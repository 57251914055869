//html, body {
//  -webkit-user-select: none;
//  -moz-user-select: none;
//  -ms-user-select: none;
//  user-select: none;
//  overflow: hidden;
//  outline: none;
//  -webkit-appearance: none;
//}

:hover, :active, :focus {
  outline: 0;
  outline: none;
}

button, div {
  outline: none;
}

html.is-locked,
html.is-locked body {
  /* want to fix the height to the window height */
  height: calc(var(--window-inner-height) - 1px);

  /* want to block all overflowing content */
  overflow: hidden;
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
}

[data-rsbs-header] {
  display: none;
}

[data-rsbs-backdrop] {
  z-index: 19 !important;
}

[data-rsbs-overlay] {
  z-index: 9999 !important;
}

.bottom_sheet_base_wrapper {
  [data-rsbs-header] {
    padding-top: 2rem !important;
  }

}

.no_drag {
  overflow: hidden;
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
}

.bottom_sheet_wrapper {
  overflow: scroll;
  position: relative;
  padding: 0 1rem 1rem 1rem;
  margin: 1rem 0;

  .project_list {
    padding-bottom: 1rem;
  }

  .project_item {
    margin-top: 1rem;
    padding: 0.7rem 0;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-left: 3px solid;

    .project_content {
      padding: 0.5rem 1rem;


      .project_title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

      }

      .project_info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        #project_created_txt {
          color: grey;
        }

        img {
          margin-right: 0.4rem;
          max-width: 15px;
        }

        #length_txt {
          color: grey;
        }
      }
    }

    &:active {
      opacity: 0.2;
    }
  }
}

.project_detail_wrapper {
  padding: 0 0 2rem 0;
  overflow: scroll;
  margin: 1rem 0;

  .detail_header {
    padding: 0 1rem 1rem 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #d5d5d5;
  }

  .detail_action_wrapper {
    padding: 1rem;
    border-bottom: 1px solid #d5d5d5;

    .btn_list {
      padding: 1rem 1rem 0 1rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .action_btn_item {
        flex-direction: column;
        align-items: center;
        display: flex;

        .circle_img {
          margin: 0;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          width: 35px;
          height: 35px;
          border-radius: 50%;
          background-color: #d5d5d5;

          &.active_work {
            background-color: #3886ff;

            img {
              filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(248deg) brightness(106%) contrast(106%);
            }
          }

          &.active_filter {
            background-color: #3886ff;

            img {
              filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(248deg) brightness(106%) contrast(106%);
            }
          }

          img {
            max-width: 18px;
          }

          &:active {
            opacity: 0.2;
          }
        }

        span {
          display: block;
        }

        small {
          color: grey;
          margin-top: 0.3rem;
        }
      }
    }


  }

  .detail_location_toggle {
    padding: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #d5d5d5;
  }


  .project_detail_status {
    padding: 1rem;

    .detail_title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

  }

}


.flex_center {
  justify-content: center;
  text-align: center;
  align-items: center;
  display: flex;
}

.google_autocomplete_wrapper {
  .google_autocomplete__control {
    padding: 0.5rem 0
  }
}


#status_color_circle {
  margin-right: 0.3rem;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.loader_wrapper {
  width: 100%;
  min-height: 300px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  svg {
    &:active {
      opacity: 0.2;
    }
  }
}

#my_location_btn {
  padding: 0.8rem;
  border-radius: 50%;
  background-color: white;
  border: none;
}


.filter_dialog_wrapper {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  //justify-content: space-between;

  .address_input_wrapper {
    span {
      padding: 0.5rem 0;
    }

    .work_mode_filter_input {

      display: flex;
      flex-direction: row;
      border: 1px solid #3886ff;
      border-radius: 15px;
      padding: 0.8rem 0.2rem;
      select {
        width: 30%;
        padding: 0 0.3rem;
        border: none;
        background-color: white;
        -webkit-appearance:none;
        -moz-appearance: none;
        appearance: none;

          background-image:
                  linear-gradient(45deg, transparent 50%, gray 50%),
                  linear-gradient(135deg, gray 50%, transparent 50%);

          background-position:
                  calc(100% - 20px) calc(0.5em + 2px),
                  calc(100% - 15px) calc(0.5em + 2px);
          background-size:
                  5px 5px,
                  5px 5px;
          background-repeat: no-repeat;
      }
      input {
        padding : 0 0.2rem;
        width: 70%;
        border: none;
      }
    }

    //input {
    //  width: 100%;
    //  padding: 0.8rem 0.2rem;
    //
    //}
  }

  .workStatus_wrapper {
    padding: 0.5rem 0;

    .worStatus_items {
      padding: 0.5rem 0;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: flex-start;
      overflow-x: scroll;

      .workStatus_item {
        margin-top: 0.8rem;
        margin-right: 0.9rem;
        border-radius: 15px;
        padding: 0.2rem 0.7rem;
        border: 1px solid #3886ff;

        &.select_status {
          background-color: #3886ff;
          color: white;
        }
      }
    }


  }


  .filter_dialog_btn_wrapper {
    z-index: 15;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem 0;

    button {
      padding: 0.5rem 0;
      width: 45%;
    }

    #init_btn {
      color: #3886ff;
      border: 1px solid #d5d5d5;
    }

    #search_btn {
      color: white;
      background-color: #3886ff;
      border: 1px solid #3886ff;
    }
  }
}
