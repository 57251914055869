.user_manage_wrapper {
  .search_box {
    margin: 0;
    padding: 0.5rem;
    background-color: #F5F5F5;
    width: 100%;

    select {
      border-right: none !important;
    }

    select, input {
      border: 1px solid #d5d5d5;
      height: 40px;
      width: 100%;
    }
  }

  .user_list_wrapper {
    .user_item {
      padding: 1rem;
      border-bottom: 1px solid #d5d5d5;
      margin: 0;

      .user_desc {
        display: flex;
        flex-direction: column;

        span {
          font-weight: bold;
          margin-bottom: 0.3rem;
        }

        small {
          color: grey;
        }
      }

      .user_manage_useful_btn_wrapper {
        margin-top: 1rem;
        text-align: center;

        button {
          width: 90%;
          border-radius: 3px;
          padding: 0.3rem 0;

          &.stop_user_btn {
            border: 1px solid red;
            color: red;
            background: none;

            &:active {
              background: red;
              color: white;
            }
          }

          &.start_user_btn {
            background: none;
            border: 1px solid #3886ff;
            color: #3886ff;

            span {
              width: 20px !important;
              height: 20px !important;
            }

            &:active {
              background: #3886ff;
              color: white;
            }
          }


        }
      }

      .user_manage_btn_wrapper {
        height: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;

        button {
          padding: 0.4rem 0.6rem;
          background-color: white;
          border: 1.4px solid #3886ff;
          border-radius: 3px;
          color: #3886ff;
          font-size: 0.875rem;

          &:active {
            background-color: #3886ff;
            color: white;
          }
        }
      }

      &:active {
        background-color: #F5F5F5;
      }
    }
  }
}

.user_manage_modal {
  span {
    font-weight: bold;
  }

  .user_info {
    display: flex;
    flex-direction: column;

    small {
      color: grey;
    }
  }

  .row {
    margin: 0;
    align-items: center;

    .col-3, .col-9 {
      padding: 0;
    }
  }

  select, input {
    width: 100%;
    height: 40px;
  }

  #extend_btn {
    margin-top: 1rem;
    width: 100%;
    background-color: #3886ff;
    color: white;
    border: 1px solid #3886ff;
    border-radius: 3px;
    padding: 0.6rem 0;

    &:active {
      background-color: white;
      color: #3886ff;
    }
  }
}


.price_manage_wrapper {
  background-color: #F5F5F5;
  height: calc(100vh - 48px);
  padding: 0.5rem;

  .price_list_item {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    background-color: white;
    padding: 1rem 0.5rem;
    border-radius: 8px;

    .price_name {
      max-width: 70%;
      color: #454545;
      font-size: 0.9rem;
    }

    .price_won {
      font-size: 1.2rem;
    }

    input {
      padding: 0 0.4rem;
      margin-top: 1rem;
      border: 1px solid grey;
      height: 40px;
    }

    button {
      margin-top: 1rem;
      padding: 0.4rem 0;
      width: 100%;
      background-color: white;
      border: 1px solid #3886ff;
      color: #3886ff;
      border-radius: 3px;

      &:active {
        background-color: #3886ff;
        color: white;
      }
    }
  }
}

.bill_record_modal {
  .modal-body {
    max-height: 400px;
    overflow-y: scroll;
  }
}

.custom_modal_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  button {
    background: none;
    border: none;
  }

  img {
    width: 30px;
    height: 30px;
  }
}

.admin_user_bill_list {
  padding: 1rem 0;

  .user_bill_item {
    margin-top: 1rem;
    border-radius: 15px;
    padding: 0.6rem 0.3rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

    .row {
      margin: 0;
      justify-content: center;
      align-items: center;
    }
  }
}


.work_record_admin_wrapper {
  .type_search_wrapper {
    padding: 1rem 0;
    background: #F5F5F5;
  }

  .record_type_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 1rem 0;

    button {
      padding: 0.7rem 0;
      width: 45%;
      background: white;
      border: 1px solid #3886ff;
      border-radius: 5px;

      &.active, &:active, &:hover {
        color: white;
        background: #3886ff;
      }
    }
  }

  .record_search_wrapper {
    padding: 0;
    margin: 0 auto;

    input {
      padding: 0 0.4rem;
      height: 40px;
      margin: 0 auto;
      width: 100%;
    }
  }


  .record_list_wrapper {
    background: white;

    .record_user_item {
      border-bottom: 1px solid gray;
      padding: 1rem;

      small {
        color: gray;
      }

      button {
        padding: 0.6rem;
        border: 1px solid #3886ff;
        background: none;
      }
    }
  }
}

.work_record_modal_body {
  max-height: 500px;
  overflow-y: scroll;
}

.work_record_admin_list_wrapper {
  .search_box {
    button {
      height: 40px;
      width: 100%;
      background-color: #3886ff;
      color: white;
      border: none;
      border-radius: 2px;

      span,svg{
        color: white;
        width: 15px!important;
        height: 15px!important;
      }
    }
  }

  .admin_user_work_record_item {
    margin-top: 1rem;
    overflow: hidden;
    border: 1px solid #999999;
    border-radius: 15px;

    span {
      color: gray;
      font-size: 0.8rem;
    }

  }

  .admin_user_record_statistics {
    border-bottom: 2px solid #3886ff;
    padding: 0.5rem 0;

    .around_space {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: 0.5rem;
    }
  }
}

.home_color {
  color: #3886ff;
}