.user_dialog_wrapper {
  padding: 0 1rem 0.5rem 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.work_mode_main_wrapper {
  position: relative;

  .map_loader_wrapper, .expire_page_main {
    background-color: white;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
  }

  .expire_page_main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      width: 70px;
    }

    span, small {
      font-weight: bold;
    }

    small {
      color: gray;
    }
  }
}

.work_dialog_wrapper {
  padding: 1rem;

  .work_action_wrapper {
    padding: 0.5rem 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .work_info {
      display: flex;
      flex-direction: column;

    }

    .work_status_chip {
      padding: 0.2rem 0.5rem;
      border: 1px solid #d5d5d5;
      border-radius: 30px;
    }

    .phone_action_btn {
      img {
        margin-right: 1rem;
      }
    }
  }

  .work_action_btn_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem 0;

    button {
      width: 45%;
      padding: 0.4rem 0;
    }

    #work_record_btn {
      background: none;
      border: 1px solid #d5d5d5;
      border-radius: 5px;
      color: #3886FF;
    }

    #change_worStatus_btn {
      border: 1px solid #d5d5d5;
      border-radius: 5px;
      background: #3886FF;
      color: white;
    }
  }
}

.my_share_location {
  display: flex;
  flex-direction: row;
  position: absolute;
  left: 16px;
  top: -45px;

  .share_location_btn {
    padding: 0.3rem 0.6rem;
    border-radius: 50px;
    font-weight: bold;
    background-color: white;

    &.active {
      border: 1px solid red;
      color: red !important;
    }

    &.disable {
      border: 1px solid #3886ff;
      color: #3886ff !important;
    }
  }
}

.my_location_btn_wrapper {
  position: absolute;
  right: 3%;
  top: -60px;
}

.google_search_bar_wrapper {
  z-index: 18;
  position: fixed;
  top: 10px;
  padding: 1rem;

  #assign_active_modal_btn {
    background-color: white;
    border: 1px solid red;
    color: red;
    border-radius: 15px;
    padding: 0.3rem 0.6rem;
    font-weight: bold;
  }

  .search_result_wrapper {
    z-index: 18;
  }

  .ant-input-search {
    input {
      width: 100%;
    }
  }

  .place_list_wrapper {
    border-radius: 5px;
    list-style: none;
    margin: 0;
    padding: 0;

    &.show_border {
      border: 1px solid #d5d5d5;
    }

    li {
      cursor: pointer;

      &:hover {
        background-color: #d5d5d5;
      }

      h4 {
        margin: 0;
        padding: 1rem;
        font-size: 1rem;
      }
    }
  }

  .ant-list-items {
    list-style: none;
    padding: 0;

    .ant-list-item-meta-content {
      padding: 0.5rem;

      h4 {
        font-size: 1.1rem;
      }
    }
  }
}

.project_not_exist {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 250px;
  color: gray;
  font-weight: bold;
  text-align: center;
}


.polygon_dialog_wrapper {
  padding: 2rem 1rem;

  .nav_btn_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    small {
      margin-top: 0.3rem;
      color: grey;
    }
  }

  #add_work_btn {
    margin-top: 1rem;
    width: 100%;
    padding: 0.5rem 0;
    background-color: #3886ff;
    border: none;
    color: white;
    border-radius: 5px;
  }
}

.map_loader_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.work_assign_modal {
  overflow: hidden;
  max-width: 100vw !important;
  margin: 0 !important;
  height: 100vh;

  .modal-content {
    border: none;
    border-radius: 0;
    height: 100%;

    .modal_header {
      padding: 10px 5px;
      text-align: left;
      position: relative;
      border-bottom: 1px solid #E6E7EB;
      display: flex;
      flex-direction: row;
      justify-content: space-between;


      button {
        background: none;
        border: none;
      }

      .work_assign_filter_btn {
        border-radius: 5px;
        border: 1px solid #3886ff;
        padding: 0.2rem 1rem;
        margin-right: 0.5rem;


        &.active, &:active {
          background-color: #3886ff;
          color: white;
        }
      }

      img {
        width: 25px;
      }

      span {
        font-weight: bold;
        font-size: 0.9rem;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .work_assign_main {
      position: relative;

      .map_container {
        height: calc(100vh - 49px);
      }

      .assign_member_list_main {
        position: absolute;
        top: 5px;
        left: 5px;
        z-index: 10;
        background: white;
        padding: 0.5rem 2rem 0.5rem 0.7rem;
        border-radius: 5px;
        transition: 0.5s ease;

        ul {
          margin: 0;
          padding: 0;
          list-style: none;

          li {
            margin-top: 0.2rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            font-weight: bold;

            .color_ball_box {
              border-radius: 50%;
              width: 10px;
              height: 10px;
              margin-right: 0.5rem;
            }
          }
        }

        &.collapse_side_bar {
          //width: 0;
          left: 0!important;
          transform: translateX(-100%);
          padding: 0;
          z-index: 10;

          .space_base_content, #space_base_title {
            display: none;
          }

          .collapse_btn_main {
            button {
              transform: rotate(180deg);

              svg {

              }
            }
          }
        }

        .collapse_btn_main {
          position: absolute;
          top: 50%;
          left: 100%;
          transform: translateY(-50%);
          z-index: 10;
          background: white;

          button {
            overflow: hidden;
            display: inline-block;
            width: 22px;
            height: 49px;
            color: gray;
            vertical-align: top;
            border: 1px solid var(--border-secondary);
            background: white;

            svg {
              width: 16px;
              height: 16px;
            }
          }
        }

      }

      .dragable_btn_wrapper {
        position: absolute;
        left: 5px;
        bottom: 40px;

        button {
          background-color: white;
          border-radius: 50px;
          padding: 0.4rem 1rem;
          font-weight: bold;
          border: 1px solid #3886ff;
        }

        &.active {
          button {
            border: 1px solid red;
            color: red;
          }
        }
      }

      .my_location_modal_btn_wrapper {
        position: absolute;
        right: 5px;
        bottom: 40px;
        z-index: 10;

        #my_location_btn {
          padding: 0.8rem;
          border-radius: 50%;
          background-color: white;
          border: none;
        }
      }
    }

  }
}

.work_assign_status_modal {
  .assignment_status_modal {
    padding: 2rem;

    .modal_header {
      font-weight: bold;
      font-size: 1.1rem;
    }

    .modal_content {
      margin-top: 1rem;

      select {
        background-color: white;
        width: 100%;
        height: 50px;
        border: 1px solid #cacaca;
        color: black;
      }
    }

    .bottom_btn_wrapper {
      padding: 1rem 0 0 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      button {
        padding: 0.6rem 0;
        border-radius: 5px;
        width: 45%;

        &:last-child {
          background-color: white;
          color: #3886ff;
          border: 1px solid #3886ff;

          &:active {
            background-color: #3886ff;
            color: white;
          }
        }

        &:first-child {
          background-color: white;
          color: black;
          border: 1px solid gray;

          &:active {
            background-color: gray;
            color: white;
          }
        }
      }
    }

  }
}

.assign_works_dialog_wrapper {
  display: flex;
  flex-direction: column;
  padding: 0 1rem 1rem 1rem;

  .button_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 1rem;

    button {
      border-radius: 5px;
      width: 45%;
      height: 40px;


      &:first-child {
        background: none;
        border: 1px solid #d5d5d5;
        border-radius: 5px;
        color: #3886FF;
      }

      &:last-child {
        border: 1px solid #d5d5d5;
        border-radius: 5px;
        background: #3886FF;
        color: white;
      }
    }
  }
}

.MuiSnackbar-root {
  z-index: 99999 !important;
}

@media (max-width: 340px) {
  .user_dialog_wrapper {
    flex-direction: column !important;
  }
}
