.work_excel_wrapper {
  padding: 0.8rem;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  .work_excel_title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    button {
      background-color: white;
      color: #3886ff;
      border: 1px solid #3886ff;
      border-radius: 8px;
      padding: 0.4rem 0.4rem;
    }
  }

  .excel_input_wrapper {
    margin-top: 1.4rem;
    position: relative;
    min-height: 150px;
    border: 1px solid rgba(0, 0, 0, 0.42);
    border-radius: 5px;
    cursor: pointer;

    .ab_ct {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        max-width: 50px;
      }

      span {
        font-weight: bold;
        font-size: 0.95rem;
        color: rgba(0, 0, 0, 0.42);
      }

      small {
        font-size: 0.75rem;
        color: rgba(0, 0, 0, 0.42);
      }

      .mt_txt {
        margin-top: 0.5rem;
      }
    }
  }

  .excel_add_btn_wrapper {
    position: absolute;
    bottom: 10px;
    left: 10px;
    right: 10px;

    button {
      border: none;
      border-radius: 5px;
      background-color: #3886ff;
      padding: 0.7rem 0;
      width: 100%;
      color: white;
    }

    .finish_btn{
      background-color: gray!important;
    }
  }

  .excel_list_loader {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .work_add_list_wrapper {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    max-height: calc(100vh - 300px);
    margin-top: 0.5rem;
    padding: 1rem 0;
    align-items: center;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .work_add_item {
      cursor: pointer;
      min-height: 80px;
      margin-top: 0.7rem;
      border-radius: 5px;
      width: 99%;
      padding: 1rem 0.5rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .work_status_txt {
        color: rgba(0, 0, 0, 0.43);
      }

      &.success {
        border: 2px solid #3886ff;

        .work_status_txt {
          color: #3886ff;
        }
      }

      &.failed {
        border: 2px solid red;

        .work_status_txt {
          color: red;
        }
      }

      &.suspend {
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
      }

      .item_address_txt {
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .work_item_arrow {
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(248deg) brightness(70%) contrast(106%);
      }
    }
  }
}

.work_excel_modal_content {
  display: flex;
  flex-direction: column;

  .work_info_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.4rem 0;

    span {
      &:last-child {
        word-break: break-all;
        max-width: 200px;
      }
    }

    .success {
      color: #3886ff;
    }

    .failed {
      color: red;
    }
  }

  .work_excel_modal_close {
    margin-top: 1rem;
    width: 100%;
    padding: 0.4rem 0;
    border: none;
    border-radius: 5px;
    background-color: #3886ff;
    color: white;
  }
}


.down_excel_form_btn{
  &:active{
    opacity: 0.3;
  }
}
